import React, { isValidElement } from "react";
import { type ItemSlotType } from "../types";

export const ItemSlot = ({ slot, className = "" }: ItemSlotType) => {
  if (typeof slot === "undefined") return null;
  return (
    <div
      className={`cui-flex cui-items-center cui-text-fg-default ${className}`}
    >
      {slot}
    </div>
  );
};

ItemSlot.displayName = "ItemSlot";
