import React, {
  memo,
  useLayoutEffect,
  isValidElement,
  useRef,
  useMemo,
} from "react";
import { ItemProps } from "./types";
import { ItemRow } from "./components/ItemRow";
import { useIdRef } from "components/src/shared/hooks/useIdRef";
import { ItemPresets, ItemPresetsType } from "./components/ItemPresets";
import { gapMap } from "components/src/shared/styles/sizeStyles";
import cx from "components/src/utils/cx";
import { useSwipe } from "components/src/shared/hooks/useSwipe";
import { SwipeOptions } from "./components/SwipeOptions";
import { createSlot } from "./js/createSlot";
import { cssColor } from "components/src/shared/utils/cssUtils";
import { ItemSlotWrapper } from "./components/ItemPresets";

export const Item = ({
  id,
  verticalAlignment = "center",
  rowSpacing = "2xs",
  colSpacing = "xs",
  bgColor = "bg-default",
  swipeOptions = [],
  swipeSpeed,
  leading,
  trailing,
  animation = true,
  className,
  children,
  ...rest
}: ItemProps) => {
  const idRef = useIdRef({ id, str: "cui__item-wrapper-" });
  const swipeWrapperRef = useRef<HTMLDivElement | null>(null);
  const hasSwipeOptions = !!swipeOptions?.length;

  const [hasLeading, hasChildren, hasTrailing] = useMemo(() => {
    return [!!leading, !!children, !!trailing];
  }, [leading, children, trailing]);

  /**
   * useSwipe CUI hook - introduce swipe action to element.
   */
  const swipeAnimationSpeed = animation ? { swipeSpeed } : { swipeSpeed: 0 };
  const { animated, swipeStyles, bind, isOpen } = useSwipe({
    enabled: hasSwipeOptions,
    ref: swipeWrapperRef,
    ...swipeAnimationSpeed,
  });

  return (
    <div
      id={idRef.current}
      className={`cui-w-full cui-bg-bg-base cui-grid cui-overflow-hidden ${className}`.trim()}
      style={{
        display: "grid",
        placeContent: "center",
        gridAutoColumns: "1fr",
        background: cssColor(bgColor),
      }}
      {...rest}
    >
      <div
        className="cui__item-inner-wrapper cui-block cui-w-full cui-relative"
        style={{ gridArea: "1/1" }}
      >
        <animated.div
          style={swipeStyles}
          {...bind()}
          className={cx(
            {
              [gapMap[colSpacing]]: !!colSpacing,
              "cui-grid-cols-[auto,1fr,auto]":
                hasLeading && hasTrailing && hasChildren,
              "cui-grid-cols-[auto,1fr]":
                hasLeading && !hasTrailing && hasChildren,
              "cui-grid-cols-[1fr,auto]":
                !hasLeading && hasChildren && hasTrailing,
              "cui-grid-cols-1": hasChildren && !hasLeading && !hasTrailing,
            },
            "cui-grid cui-w-full cui-h-full cui-bg-bg-default cui-touch-none"
          )}
        >
          {/* Leading Area */}
          {isValidElement(leading) ? (
            <ItemSlotWrapper verticalAlignment={verticalAlignment}>{leading}</ItemSlotWrapper>
          ) : (
            <ItemPresets
              type={leading as ItemPresetsType}
              verticalAlignment={verticalAlignment}
            />
          )}
          {/* Main Area  */}
          <div
            className={`cui-flex-grow cui-flex cui-flex-col ${gapMap[rowSpacing]}`}
          >
            {children}
          </div>
          {/* Trailing Area  */}
          {isValidElement(trailing) ? (
            <ItemSlotWrapper verticalAlignment={verticalAlignment}>{trailing}</ItemSlotWrapper>
          ) : (
            <ItemPresets
              type={trailing as ItemPresetsType}
              verticalAlignment={verticalAlignment}
            />
          )}
          {/* Swipe Options */}
          {hasSwipeOptions ? (
            <div
              className="cui-absolute cui-right-0 cui-translate-x-[100%] cui-pl-sm cui-h-full cui-top-0 cui-flex cui-flex-row-reverse cui-items-center"
              style={{ gridArea: "1/1" }}
              ref={swipeWrapperRef}
            >
              <SwipeOptions options={swipeOptions} isOpen={isOpen} />
            </div>
          ) : null}
        </animated.div>
      </div>
    </div>
  );
};

Item.displayName = "Item";

Item.Row = ItemRow;
Item.Slot = createSlot;
