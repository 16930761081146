import React, { memo } from "react";
import { ItemSlot } from "./ItemSlot";
import { type ItemRowType } from "../types";

const ItemRowWrapper = ({ children }) => {
  return (
    <div className="cui-w-auto cui-flex cui-items-center cui-justify-between cui-text-fg-default cui-gap-xs">
      {children}
    </div>
  );
};
const BetslipItemRow = ({ children }) => {
  return <ItemRowWrapper>Betslip Item</ItemRowWrapper>;
};

export const ItemRow = memo(
  ({
    leadingStart,
    leadingEnd,
    trailingStart,
    trailingEnd,
    children,
    type,
  }: ItemRowType) => {
    if (type === "betslip-item") return <BetslipItemRow children={children} />;
    return (
      <ItemRowWrapper>
        <ItemSlot slot={leadingStart} />
        <ItemSlot slot={leadingEnd} />
        {!!children ? (
          <ItemSlot slot={children} className="cui-flex-grow" />
        ) : (
          <div className="cui-flex-grow" />
        )}
        <ItemSlot slot={trailingStart} />
        <ItemSlot slot={trailingEnd} />
      </ItemRowWrapper>
    );
  }
);

ItemRow.displayName = "ItemRow";
