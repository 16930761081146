import { useState } from "react";
import { resolveTeam } from "./js/resolveTeam";
import { resolveIcon } from "./js/resolveIcon";
import { resolveReadability } from "./js/resolveReadability";
import { resolveAriaLabel } from "./js/resolveAriaLabel";
import { useSize } from "../../shared/hooks/useSize";
import { isUseableType } from "components/src/shared/utils/isUseableType";

import { type CompetitorProps } from "./types";

import { CompetitorSkeleton } from "./components/CompetitorSkeleton";
import { CompetitorVertical } from "./components/CompetitorVertical";
import { CompetitorHorizontal } from "./components/CompetitorHorizontal";

export const Competitor = ({
  icon,
  country,
  displayName,
  force = null,
  team = null,
  usePreferredName = false,
  sport = "basketball",
  iconSize,
  inverse = false,
  home = false,
  possession = false,
  rank,
  score,
  groupBy,
  description = "",
  useAvatar = false,
  avatar: _avatar,
  showAnimation = true,
  showIcon = true,
  center = false,
  textSize = "heading-md",
  skeleton = false,
  tabbable = true,
  hasPlayerName,
  variant = "horizontal",
  breakpoints = {
    abbr: 140,
    full: 240,
  },
  compact = false,
  lineClamp = 2,
  mode = "light",
  className = "",
  forceReadability = false,
  inline,
  sync,
  ...rest
}: CompetitorProps) => {
  
  if (skeleton)
    return <CompetitorSkeleton inverse={inverse} iconSize={iconSize} />;

  const hasContainerQueries =
    window && window.CSS
      ? CSS?.supports("container-type", "inline-size")
      : false;

  const [competitorRef, setCompetitorRef] = useState<HTMLDivElement | null>(
    null
  );

  // Resolve a team if passed in
  const {
    teamInfo,
    isWomens,
    hasTeam,
    hasFoundTeam,
    hasCountry,
    isTeamCountry,
    isDrawTeam,
    _team,
    _displayName,
  } = resolveTeam({ team, displayName, country });

  // Resolve the Icon to use
  const { showComputedIcon, activeIcon } = resolveIcon({
    _avatar,
    showIcon,
    variant,
    country,
    iconSize,
    sport,
    mode,
    hasFoundTeam,
    _team,
    teamInfo,
    hasCountry,
    isTeamCountry,
    icon,
    hasTeam,
    isDrawTeam,
    useAvatar,
  });

  // Fallback to ResizeObserver when no Container query support
  const { width } = useSize(competitorRef, 100, hasContainerQueries) || {
    width: "auto",
    height: "auto",
  };

  const hasScore = isUseableType(score);

  // Resolve the computed aria-label
  const ariaLabel = resolveAriaLabel({
    teamInfo,
    _displayName,
    description,
    hasScore,
    score,
    possession,
  });

  // Resolve readable tokens (this makes sure text is readable on whatever background it's on)
  const { readableToken, hasForceReadability } = resolveReadability({
    forceReadability,
    teamInfo,
  });

  // console.log("Rendering Competitor", team, displayName, {width, height})

  const scoreWithPossesionProps = {
    score,
    compact,
    possession,
    showAnimation,
    inverse,
    sport,
    variant,
    hasScore,
    size: undefined,
    sync
  };
  const competitorNameProps = {
    displayName: _displayName,
    teamInfo,
    force,
    width,
    rank,
    textSize,
    inverse,
    breakpoints,
    hasContainerQueries,
    isWomens,
    lineClamp,
    variant,
  };

  const competitorWrapperProps = {
    variant,
    showComputedIcon,
    className,
    ariaLabel,
    tabbable,
    rest,
  };

  const competitorVariantProps = {
    wrapperProps: competitorWrapperProps,
    showComputedIcon,
    activeIcon,
    inverse,
    rank,
    readableToken,
    hasForceReadability,
    competitorNameProps,
    scoreWithPossesionProps,
    hasScore,
    description,
    variant,
    center,
    compact,
    hasContainerQueries,
    inline,
    sync,
    setCompetitorRef,
  };

  if (variant === "vertical") return <CompetitorVertical {...competitorVariantProps} />;
  return <CompetitorHorizontal {...competitorVariantProps} />;
};