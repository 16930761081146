import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { ToggleKnobProps, ToggleProps, ToggleTrackProps } from "./types";
import { Icon } from "../Icon/Icon";
import { Loading } from "../Loading/Loading";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import { cssColor } from "components/src/shared/utils/cssUtils";
import isEmpty from "lodash-es/isEmpty";

import styles from "./scss/Toggle.module.scss";
import { customColorCssVarMap } from "./js/customColorCssVarMap";
import { iconMap } from "./js/iconMap";

export const Toggle = ({
  id = "",
  selected = false,
  size = "lg",
  shape = "round",
  type = "primary",
  icon,
  disabled = false,
  customColors,
  accessibilityLabel = "Toggle",
  showAnimation = true,
  className,
  disableToggleOnSelected,
  controlled,  
  onToggle,
  ...rest
}: ToggleProps) => {
  const [_selected, setSelected] = useState(selected);
  const localId = useRef(`cui__toggle-id-${randomIdString()}`);
  const _id = !!id ? id : localId.current;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isFirstRender = useRef(true);

  const _type = disabled ? "disabled" : type;
  const isPending = useMemo(() => type === "pending", [type]);
  const animationClass = showAnimation
    ? styles[`cui__toggle-with-animation`]
    : "";
  const wrapperClasses = `${styles["cui__toggle"]} ${
    styles[`cui__toggle-type-${_type}`]
  } ${styles[`cui__toggle-size-${size}`]} ${
    styles[`cui__toggle-shape-${shape}`]} ${animationClass} ${className}
  }`.trim();

  const customColorsValues =
    !!customColors && !isEmpty(customColors) ? Object.values(customColors) : [];
  const toggleStyles = useMemo(() => {
    let styles = {};
    if (customColors && !isEmpty(customColors) && !disabled) {
      styles = Object.entries(customColors).reduce((acc, [name, value]) => {
        acc[customColorCssVarMap[name]] = cssColor(value);
        return acc;
      }, {});
    }
    return styles;
  }, [...customColorsValues, disabled]);

  const _disabledState = disabled ? { disabled } : {};
  const _checkedState = !disabled && _selected ? { defaultChecked: true } : {};
  const computedStates = { ..._disabledState, ..._checkedState };

  const handleChange = useCallback((e) => {
    if (disabled || isPending) return;
    if (!controlled) {
      const checked = e.target.checked;
      setSelected(e.target.checked);
    }
    if (disableToggleOnSelected || controlled) {
      onToggle && onToggle({ id: _id, selected: controlled ? _selected : e.target.checked })
    };
  }, [disableToggleOnSelected, controlled, _selected, isPending, onToggle]);

  useEffect(() => {
    !disabled && !isPending &&
      setSelected((v) => {
        if (inputRef.current) inputRef.current.checked = selected;
        return selected;
      });
  }, [selected, disabled, isPending]);

  useEffect(()=>{
    if (!isFirstRender.current) {
      if(!disableToggleOnSelected) onToggle && onToggle({ id: _id, selected: _selected });
    } else {
      isFirstRender.current = false;
    }
  }, [_selected, onToggle]);

  return (
    <div className={wrapperClasses} style={toggleStyles} {...rest}>
      <input
        className="cui-sr-only"
        type="checkbox"
        id={_id}
        role="switch"
        tabIndex={0}
        aria-checked={_selected}
        aria-label={accessibilityLabel}
        onChange={handleChange}
        {...computedStates}
        ref={inputRef}
      />
      <Track htmlFor={_id} type={_type} selected={selected}>
        <Knob
          icon={icon}
          type={_type}
          selected={selected}
          disabled={disabled}
          size={size}
        />
      </Track>
    </div>
  );
};

const Knob = ({ icon, type, size, disabled }: ToggleKnobProps) => {
  let _icon = type ? (
    <Icon name={iconMap[type]} size={size === "sm" ? "xs" : "sm"} />
  ) : null;
  if (icon && typeof icon === "object" && "name" in icon && !disabled)
    _icon = <Icon {...icon} />;
  if (type === "pending") _icon = <Loading size="xs" />
  return <div className={styles["cui__toggle-knob"]}>{_icon}</div>;
};

const Track = ({ children, htmlFor }: ToggleTrackProps) => {
  return (
    <label htmlFor={htmlFor} className={styles["cui__toggle-track"]}>
      {children}
    </label>
  );
};
