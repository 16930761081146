import React, { useState, type MutableRefObject } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { config } from "cypress/types/bluebird";

export const useSwipe = ({ enabled, ref, swipeXOffset = 0, swipeX = 0, swipeSpeed }: { enabled?: boolean, ref?: MutableRefObject<HTMLElement | null>, swipeX?: number, swipeXOffset?: number, swipeSpeed?: number }) => {
    if (!enabled) return { swipeStyles: {}, animated, bind: () => {}, isOpen: false };

    const configObj = swipeSpeed !== undefined && typeof swipeSpeed === "number" ?  { config: { duration: swipeSpeed }} : {};
    console.log({configObj, swipeSpeed})
    const [isOpen, setIsOpen] = useState<boolean>(false);
    /**
     * React Spring
     */
    const [{ translateX }, api] = useSpring(() => ({
        translateX: 0,
        ...configObj
      }));
    
    const springStyles = { translateX, gridArea: "1 / 1" }

    /**
     * useDrag - useGesture hook
     */
    const bind = useDrag(
      (state) => {
        const { tap, direction: [dirX,], delta: [dx], swipe: [swipeDirX,], intentional } = state;
        if (!intentional) return;

        // Swipe Left
        if (!tap && (swipeDirX === -1 || dx < -2) && enabled) {
           api.start({ translateX: ref?.current ? -(ref?.current?.clientWidth + swipeXOffset) : -(swipeX + swipeXOffset) });
           setIsOpen(true);
        }
        // Swipe Right
        if (!tap && (swipeDirX === 1 || dx > 2) && enabled) {
          api.start({ translateX: 0 });
          setIsOpen(false);
        }
      },
      {
        axis: "x",
        filterTaps: true,
        from() {
          return [0, translateX.get()];
        },
        DragBounds: { left: 0 },
        rubberband: 0.06,
        swipe: {
          duration: 10,
          distance: [25,25],
          velocity: [.6,.6]
        }
      }
    );

    return { animated, bind, swipeStyles: springStyles, isOpen }
}