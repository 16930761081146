import React, {
  useMemo,
  useCallback,
  isValidElement,
  type ReactNode,
  type MutableRefObject,
} from "react";
import { type ItemSwipeOptionType } from "../types";
import { Icon } from "components/src/components/Icon/Icon";
import { Heading } from "components/src/components/Typography/Typography";
import cx from "components/src/utils/cx";
import { useIdRef } from "components/src/shared/hooks/useIdRef";
import { cssColor } from "components/src/shared/utils/cssUtils";

export const SwipeOptions = ({
  options,
  isOpen,
}: {
  options: ItemSwipeOptionType[];
  isOpen: boolean;
}): JSX.Element | null => {
  if (!options.length) return null;
  return (
    <>
      {options.map<ItemSwipeOptionType | ReactNode>((option) => {
        if (isValidElement(option)) return option;

        const {
          id = "",
          text,
          fgColor = "fg-inverse",
          bgColor = "bg-base",
          icon,
          iconSize,
          iconColor = "fg-inverse",
          onClick,
        } = option;

        const _id = useIdRef({ id: `${id}`, str: "cui__swipe-option" });

        /**
         * Icon setup
         */
        let _icon;
        if (typeof icon === "string")
          _icon = <Icon name={icon} size={iconSize} color={iconColor} />;
        if (typeof icon === "object")
          _icon = <Icon size={iconSize} color={iconColor} {...icon} />;

        /**
         * Style constructor
         */
        const styles = useMemo(() => {
          return {
            background: cssColor(bgColor),
          };
        }, [bgColor]);

        /**
         * Event Handlers
         */
        const handleClick = useCallback(
          (e) => {
            onClick && onClick({ e, id });
          },
          [onClick]
        );

        return (
          <div
            id={_id.current}
            className="cui__item-swipe-option-wrapper cui-flex cui-flex-col cui-min-w-[44px] cui-h-full cui-items-center cui-justify-around cui-px-xs cui-cursor-pointer cui-select-none"
            style={styles}
            onClick={handleClick}
          >
            <div className="cui-flex cui-flex-col cui-items-center cui-gap-2xs">
              {_icon}
              <Heading color={fgColor} size="sm-bold">
                {text}
              </Heading>
            </div>
          </div>
        );
      })}
    </>
  );
};
