import { type ReactNode } from "react";
import { DivProps } from "react-html-props";
import { Icon } from "../../Icon/Icon";
import { Loading } from "components/src/components/Loading/Loading";
import { cssColor } from "components/src/shared/utils/cssUtils";
import { type ItemVerticalAlignmentType } from "../types";

const verticalAlignmentMap: Record<ItemVerticalAlignmentType, string> = {
  top: "cui-items-start",
  center: "cui-items-center",
  bottom: "cui-items-end",
};
export const ItemSlotWrapper = ({
  children,
  className = "",
  onClick,
  verticalAlignment,
  ...rest
}: DivProps & {
  children?: ReactNode;
  className?: string;
  verticalAlignment: ItemVerticalAlignmentType;
}) => {
  return (
    <div
      className={`cui-w-auto cui-h-full cui-flex ${verticalAlignmentMap[verticalAlignment]} ${className}`.trim()}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};

export const ItemPresetsObj = {
  "color-bar": ({ options, onClick }, verticalAlignment) => {
    return (
      <ItemSlotWrapper
        verticalAlignment={verticalAlignment}
        onClick={onClick}
        style={{
          background: `${cssColor(options?.color)}`,
          minWidth: `var(--spacing-sm)`,
        }}
      />
    );
  },
  "alert-icon": ({ options, onClick }, verticalAlignment) => (
    <ItemSlotWrapper verticalAlignment={verticalAlignment} onClick={onClick}>
      <Icon name="ui_warning" color="status-alert" />
    </ItemSlotWrapper>
  ),
  "right-chevron": ({ options, onClick }, verticalAlignment) => (
    <ItemSlotWrapper verticalAlignment={verticalAlignment} onClick={onClick}>
      <Icon name="ui_chevron-right" color="fg-primary" />
    </ItemSlotWrapper>
  ),
  remove: ({ options, onClick }, verticalAlignment) => (
    <ItemSlotWrapper verticalAlignment={verticalAlignment} onClick={onClick}>
      <Icon name="ui_minus_circle" color="status-negative" />
    </ItemSlotWrapper>
  ),
  "trash-can": ({ options, onClick }, verticalAlignment) => {
    return (
      <ItemSlotWrapper verticalAlignment={verticalAlignment} onClick={onClick}>
        <Icon name="ui_trash" color="status-negative" />
      </ItemSlotWrapper>
    );
  },
  loading: ({ options, onClick }, verticalAlignment) => (
    <ItemSlotWrapper verticalAlignment={verticalAlignment} onClick={onClick}>
      <Loading />
    </ItemSlotWrapper>
  ),
};

export type ItemPresetsNameType =
  | "color-bar"
  | "alert-icon"
  | "right-chevron"
  | "remove"
  | "trash-can"
  | "loading";
export type ItemPresetsType = {
  name: ItemPresetsNameType;
  options?: { color?: string };
  onClick?: (e: MouseEvent) => void;
};
export type ItemPresetsProps = {
  type?: ItemPresetsType;
  verticalAlignment: ItemVerticalAlignmentType;
};

export const ItemPresets = ({
  type,
  verticalAlignment,
}: ItemPresetsProps): JSX.Element | null => {
  if (!type) return null;
  const { name, options, onClick } = type;
  const typeIsObj = type && typeof type === "object" && "name" in type;
  if (typeIsObj && name)
    return ItemPresetsObj[name]({ options, onClick }, verticalAlignment);
  return <></>;
};
