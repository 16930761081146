import React, { useCallback, useEffect, useRef } from "react";
import { PropsWithChildren } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { type UniversalOverlayProps } from "./types";
import cx from "components/src/utils/cx";

import { Button } from "components/src/components/Button/Button";
import { Icon } from "components/src/components/Icon/Icon";
import { Heading, Body } from "components/src/components/Typography/Typography";
import debounce from "lodash-es/debounce";

import OverlayCloseIcon from "./components/OverlayCloseIcon";
import styles from "./scss/Overlay.module.scss";

export const Overlay = ({
  root,
  type = "modal",
  open = true,
  onOpenChange = () => {},
  showClose = true,
  stayOpen = false,
  size,
  content,
  children,
  headerDivider,
  ctaInline = true,
  ctaSticky = false,
  textAlign,
  zIndex,
  canInteractWithBackground = false,
  repositionBottomOnMobile = false,
  onDismissHandler,
  onOpenHandler,
  ...rest
}: PropsWithChildren<UniversalOverlayProps>) => {
  const sheet = type.toLowerCase() === "sheet";
  const halfSheet = type.toLowerCase() === "half-sheet";
  const [left, center, right, justify] = [
    textAlign === "left",
    textAlign === "center",
    textAlign === "right",
    textAlign === "justify",
  ];

  const dialogRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (window !== undefined && repositionBottomOnMobile && window?.innerWidth <= 768) {
      const isAndroid = /Android/i.test(navigator.userAgent);
      const moveOverlay = debounce(() => {
        if (dialogRef.current && !isAndroid) {
          const viewportDifference =
            window?.innerHeight - (window?.visualViewport?.height ?? 0);

          dialogRef.current.style.paddingBottom = `calc(${viewportDifference}px + var(--spacing-lg))`;
        }
      }, 50);

      window?.visualViewport?.addEventListener("resize", moveOverlay);

      return () => {
        window?.visualViewport?.removeEventListener("resize", moveOverlay);
      };
    }
  }, [repositionBottomOnMobile, dialogRef]);

  const _root = root && document?.getElementById(root) ? document?.getElementById(root) : document?.body;
  if (open && !_root?.classList.contains(styles["cui__overlay"])) _root?.classList.add(styles["cui__overlay"]);
  const portalProps = root ? { container: _root } : {};

  const handleDismiss = useCallback((event) => {
    if (onDismissHandler) onDismissHandler(event);
  }, []);

  const handleOpen = useCallback((event) => {
    if (onOpenHandler) onOpenHandler(event);
  }, []);

  return (
    <Dialog.Root
      modal={!canInteractWithBackground}
      open={open}
      onOpenChange={stayOpen ? () => {} : onOpenChange}
      {...rest}
    >
      <Dialog.Portal {...portalProps}>
        <Dialog.Overlay
          style={zIndex ? { zIndex } : {}}
          className={cx(
            {
              halfSheet,
            },
            `DialogOverlay`
          )}
        />
        <Dialog.Content
          ref={dialogRef}
          style={zIndex ? { zIndex: zIndex + 1 } : {}}
          tabIndex={-1}
          aria-modal={true}
          onCloseAutoFocus={handleDismiss}
          onOpenAutoFocus={handleOpen}
          className={cx(
            {
              halfSheet,
              sheet,
              large: size === "lg",
              ctaSticky,
              hasHeader: !!content?.header,
              "cui-text-left": left,
              "cui-text-center": center,
              "cui-text-right": right,
              "cui-text-justify": justify,
            },
            "DialogContent"
          )}
        >
          {content?.header && (
            <div className="DialogHeader">{content.header}</div>
          )}
          {showClose && !stayOpen && (
            <div className="DialogCloseIcon">
              <Button
                tabIndex={1}
                type="tertiary"
                aria-label="Close"
                onClick={() => {
                  onOpenChange(false);
                }}
              >
                <OverlayCloseIcon />
              </Button>
            </div>
          )}
          {content?.subtitle && <div>{content.subtitle}</div>}
          {content?.icon && <Icon {...content.icon}></Icon>}
          {content?.title && (
            <Dialog.Title asChild={true}>
              <Heading size="xl-bold">
                <>{content.title}</>
              </Heading>
            </Dialog.Title>
          )}

          {headerDivider && headerDivider !== "none" && (
            <div
              className={cx(
                {
                  small: headerDivider === "sm",
                  "cui-ml-auto": right,
                  "cui-mx-auto": center,
                  "cui-mr-auto": left,
                },
                "DialogContentDivider"
              )}
            />
          )}

          {content?.media && <div>{content.media}</div>}
          {content?.slot && <div>{content.slot}</div>}
          {(content?.body || children) && (
            <Dialog.Description asChild={true}>
              {children ? (
                children
              ) : content?.body ? (
                <Body className={cx({ sheet }, "DialogBody")} size="md">
                  <>{content.body}</>
                </Body>
              ) : null}
            </Dialog.Description>
          )}
          {content?.list && content.list.length > 0 && (
            <ol className="DialogList">
              {content.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          )}
          {content?.disclaimer && <div>{content.disclaimer}</div>}

          {(content?.primaryCta || content?.secondaryCta) && (
            <div
              className={cx(
                {
                  ctaSticky,
                  ctaInline,
                  sheet,
                },
                "DialogButtonContainer"
              )}
            >
              {content?.primaryCta && (
                <Button tabIndex={1} {...content.primaryButton}>
                  {content.primaryCta}
                </Button>
              )}
              {content?.secondaryCta && (
                <Button
                  tabIndex={1}
                  {...{ type: "minimal", ...content.secondaryButton }}
                >
                  {content.secondaryCta}
                </Button>
              )}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
