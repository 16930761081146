import React, { useMemo, useRef, type ReactNode } from "react";

import { Badge } from "components/src/components/Badge/Badge";
import { type BadgeProps } from "components/src/components/Badge/types";

import { Icon } from "components/src/components/Icon/Icon";
import { IconProps } from "components/src/components/Icon/types";

import { Avatar } from "components/src/components/Avatar/Avatar";
import { AvatarProps } from "components/src/components/Avatar/types";

import { Competitor } from "components/src/components/Competitor/Competitor";
import { CompetitorProps } from "components/src/components/Competitor/types";

import { TeamLogo } from "components/src/components/TeamLogo/TeamLogo";
import { TeamLogoProps } from "components/src/components/TeamLogo/types";

import { Details } from "components/src/components/Details/Details";
import { DetailsProps } from "components/src/components/Details/types";

import { Price } from "components/src/components/Price/Price";
import { PriceProps } from "components/src/components/Price/types";

import { Button } from "components/src/components/Button/Button";
import { ButtonProps } from "components/src/components/Button/types";

import isEqual from "lodash-es/isEqual";

export function createSlot(data: { as: "avatar" } & AvatarProps): JSX.Element | null;
export function createSlot(data: { as: "button" } & ButtonProps): JSX.Element | null;
export function createSlot(
  data: { as: "competitor" } & CompetitorProps
): JSX.Element | null;
export function createSlot(data: { as: "price" } & PriceProps): JSX.Element | null;
export function createSlot(data: { as: "teamlogo" } & TeamLogoProps): JSX.Element | null;
export function createSlot(data: { as: "details" } & DetailsProps): JSX.Element | null;
export function createSlot(data: { as: "icon" } & IconProps): JSX.Element | null;
export function createSlot(data: { as: "badge" } & BadgeProps): JSX.Element | null;
export function createSlot(data): JSX.Element | null {
  const prevData = useRef(data);
  const equal = isEqual(data, prevData.current);
  const component = useMemo(() => {
    const { as, ...props } = data;
    if (as && typeof as === "string") {
      if (as === "avatar") return <Avatar {...props} />;
      if (as === "badge") return <Badge {...props} />;
      if (as === "icon") return <Icon {...props} />;
      if (as === "competitor") return <Competitor {...props} />;
      if (as === "teamlogo") return <TeamLogo {...props} />;
      if (as === "details") return <Details {...props} />;
      if (as === "price") return <Price {...props} />;
      if (as === "button") return <Button {...props} />;
    }
    return null;
  }, [equal]);
  prevData.current = data;
  return component;
}
