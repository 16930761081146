 /**
 * Competitor Sync w/ Mapper
 * Take the existing sync object from eventDetails and pass it to Competitor too, mapping out just the possession/score props that we care about.
 */

const mapperFactory = (homeOrAway) => {
  return (data) => {
    if (!homeOrAway) return data;
    const _data = homeOrAway in data ? data[homeOrAway] : null;
    return { score: _data?.score, possession: _data?.possession }
  }
}

export function mapCompetitorSync({
  hasCompetitor, hasCompetitors, type, i, draftMarket, market, eventDetails, sport
}) {
  let _sync: any = () => ({});
  if ("sync" in eventDetails) {
    
    _sync = (homeOrAway) => {
      return ({ subscribe: eventDetails.sync.subscribe, getSnapshot: eventDetails.sync.getSnapshot, mapper: mapperFactory(homeOrAway) })
    }
    
    if (hasCompetitor && type === "sixpack") {
      if (i === 0) {
        draftMarket.competitor.sync = _sync("away");
      } else if (i === 1) {
        draftMarket.competitor.sync = _sync("home");
      }
    } else if (hasCompetitors && type === "sixpack") {
      if (sport === "soccer") {
        draftMarket.competitors[0] = { sync: _sync("home") };
        draftMarket.competitors[1] = { sync: _sync("away") };
      } else {
        draftMarket.competitors[0] = { sync: _sync("away") };
        draftMarket.competitors[1] = { sync: _sync("home") };
      }
    }
  }
}
